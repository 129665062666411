<script lang="ts">
    import { isGameCreator, lastRoundIncompleted } from "../services/store";
    import { dbPageKey, dbQuestion } from "../services/database";
    import Feedback from "../icons/feedback.svelte";
    import { getParams } from "../services/utils";

    export let roundValue: string;
    let question: string = "";
    let disabled: boolean = true;

    $: {
        disabled = !question.trim();
    }

    const saveToDbnChangePage = async (resume: boolean) => {
        try {
            await dbQuestion().set(question);

            if (!!getParams("sendIframeEvent") && +roundValue === 1) {
                if (!resume) {
                    window?.DIVE_APP.sendEvent({
                        type: "mtfb-events",
                        payload: {
                            type: "AddToAgendaClose",
                            question: question,
                        },
                    })
                } else {
                    window?.DIVE_APP.sendEvent({
                        type: "mtfb-events",
                        payload: {
                            type: "AddToAgendaCont",
                            question: question,
                        },
                    })
                }
            }
            await dbPageKey().set("WRITE_FEEDBACK_SCREEN");
        } catch (err) {
            console.log(err);
        }
    }

</script>
<div class="createFeedbackCont">
    {#if $isGameCreator}
        <div class="header">Gather Meeting Feedback</div>
        <textarea class="questionInput" name="questionInput" id="questionInput" bind:value={question} maxlength="250" placeholder="Enter your question here" />
        <div class="instructionText">Users will be able to vote using a 👍 or 👎 and optionally, can add a feedback note.</div>
        <div class="buttonCont">     
            <button class="breakBtn" on:click={() => saveToDbnChangePage(false)} {disabled}>{!!getParams("sendIframeEvent") && +roundValue === 1 ? "Add to Agenda" : "Ask"}</button>   
            {#if !!getParams("sendIframeEvent") && +roundValue === 1}
                <button class="continueBtn" on:click={() => saveToDbnChangePage(true)} {disabled}>Ask Now</button>
            {/if}
        </div>
        <div class="note">Answers will be anonymous</div>
    {:else}
        <div class="headerNormal">
            <Feedback /> MEETING FEEDBACK
        </div>

        <div class="fettiCont" class:centerText={!$lastRoundIncompleted}>
            {#if $lastRoundIncompleted}
                <div class="fettiIcon"><img src="/images/skipped.png" height="60px" width="60px" alt="skipped"></div>
            {/if}
            <div class="completionText">
                {#if $lastRoundIncompleted}
                    Host has stopped  feedback <br/> on the question.
                {:else}
                    Waiting for creator to submit the question.
                {/if}
            </div>
        </div>
    {/if}
</div>


<style>
    button:disabled {
		opacity: 0.5;
		cursor: no-drop;
	}
    .createFeedbackCont {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
    }

    .header {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        color: #060D19;
        opacity: 0.84;
    }

    .headerNormal {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: #8989A7;
        gap: 10px;
    }

    .fettiCont {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 10px;
        bottom: 94px;
        left: 50%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        transform: translateX(-50%);
        align-items: center;
    }

    .completionText {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #060D19;
        opacity: 0.84;
    }

    .questionInput {
        background: #FFFFFF;
        outline: none;
        border: 1px solid #DCDCDC;
        border-radius: 10px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        min-height: 79px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .instructionText {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #636F84;
        margin-top: -5px;
        margin-bottom: 15px;
    }

    .buttonCont {
        display: flex;
        flex-direction: column;
    }

    .breakBtn {
        background: #6C44A8;
        border-radius: 8px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    }

    .continueBtn {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #565E6C;
        cursor: pointer;
    }

    .note {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #636F84;
        margin-top: -12px;
        justify-content: center;
    }

    .centerText {
        bottom: 143px;
    }
</style>