<script lang="ts">
    import { isGameCreator, onlineUserStore, showNewQuestionModal } from "../services/store";
    import { downloadCSV, convertToCSV, formatDate } from "../services/utils";
    import { dbFeedbacks, dbPageKey, dbQuestion, dbUserFeedbackPage, dbUserFeedbackStatus, dbUserFeedbackText, listenOnFirebaseKey, user } from "../services/database";
    import Feedback from "../icons/feedback.svelte";
    import Fetti from "../icons/fetti.svelte";
    import Export from "../icons/export.svelte";
    import { getParams } from "../services/utils";

    import type { FeedbackUnit } from "../interfaces/pages.interface";

    let question: string = "";
    let feedbackText: string = "";
    let userFeedbackPage: string = "";
    let status: boolean;
    let countUpvote:number = 0
    let countDownvote:number = 0;
    let feedbackResponses: {status: boolean, text: string}[] = [];
    let creatorPage: string = "votes";
    let everyOneVoted: boolean = false;

    $: {
        if ($onlineUserStore.length - 1 <= (countUpvote + countDownvote)) everyOneVoted = true;
        else everyOneVoted = false;
    }

    listenOnFirebaseKey(dbFeedbacks(), val => {
        let tempCountUpvote = 0;
        let tempCountDownvote = 0;
        let tempFeedbackResponses = [];
        
		Object.entries(val).forEach((el: [string, FeedbackUnit])  => {
            if (el[1].status !== undefined && el[1].status === true) tempCountUpvote++;
            else if (el[1].status !== undefined && el[1].status === false) tempCountDownvote++;

            if (el[1].text !== undefined)  {
                tempFeedbackResponses.push({
                    "status": el[1].status,
                    "text": el[1].text,
                })
            }
        })

        countUpvote = tempCountUpvote;
        countDownvote = tempCountDownvote;
        feedbackResponses = tempFeedbackResponses;
	});

    listenOnFirebaseKey(dbQuestion(), val => question = val);
    listenOnFirebaseKey(dbUserFeedbackStatus(user.id || getParams("userId")), val => status = val);

    listenOnFirebaseKey(dbUserFeedbackPage(user.id || getParams("userId")), 
        val => userFeedbackPage = val, 
        async () => await changeFeedbackPage("vote"));

    const changeFeedbackPage = async (page: string) => {
        try {
            await dbUserFeedbackPage(user.id || getParams("userId")).set(page);
        } catch (err) {
            console.log(err);
        }
    }

    const vote = async (status: boolean) => {
        try {
            await dbUserFeedbackStatus(user.id || getParams("userId")).set(status);
            await changeFeedbackPage("typeFeedback");
        } catch (err) {
            console.log(err);
        }
        
    }

    const submitOptionalfeedbackText = async () => {
        try {
            if (!!feedbackText.trim()) await dbUserFeedbackText(user.id || getParams("userId")).set(feedbackText);
            await changeFeedbackPage("completed");
        } catch (err) {
            console.log(err);   
        }
    }

    const exportCsv = async () => {
        const data = feedbackResponses.map(el => {
            return {
                vote: el.status ? "Yes" : "No",
                suggestion: el.text,
            }
        })

        const csvData = convertToCSV(data);
		downloadCSV(csvData, "dive_feedbacks_" + formatDate(new Date()) + ".csv");
    }
</script>

{#if $isGameCreator}
    <div class="writeFeedbackCont">
        <div class="header">
            <Feedback /> MEETING FEEDBACK
        </div>
        {#if everyOneVoted}
            <div class="everyoneVoted">Everyone has contributed! 🚀</div> 
        {/if}
        <div class="question" class:allVotedQuestion={everyOneVoted}>{question}</div>

        {#if creatorPage === "votes"}
            <div class="votesPageOptions">
                <div class="voteBtns">
                    <button class="upvote" class:selected={status !== undefined && status === true} class:highestVote={everyOneVoted && countUpvote >= countDownvote} on:click={() => creatorPage = "answers"}>
                        <img src="/images/upvote.png" height="24px" width="24px" alt="upvote">
                        <div class="count">{countUpvote}</div>
                    </button>
                    <button class="downvote" class:selected={status !== undefined && status === false} class:highestVote={everyOneVoted && countUpvote <= countDownvote} on:click={() => creatorPage = "answers"}>
                        <img src="/images/downvote.png" height="24px" width="24px" alt="downvote">
                        <div class="count">{countDownvote}</div>
                    </button>
                </div>
                <div class="creatorOptions">
                    <div class="newQuestion" on:click={() => showNewQuestionModal.set(true)}>+ New question</div>
                    <div class="suggestions" on:click={() => creatorPage = "answers"}>See suggestions ></div>
                </div> 
            </div>     
        {:else if creatorPage === "answers"}
            <div class="answerPageCont">
                <div class="answersOptions">
                    <div class="exportCont" on:click={exportCsv}>
                        {#if feedbackResponses.length > 0}
                            <div class="exportIcon"><Export /></div>
                            <div class="exportText">Export CSV</div>
                        {/if}
                    </div>
                    <div class="back" on:click={() => creatorPage = "votes"}>{"< Back"}</div>
                </div>
                <div class="feedbackCont">
                    {#if feedbackResponses.length > 0}
                        {#each feedbackResponses as response}
                            <div class="feedback">
                                {#if response.status}
                                    <div><img src="/images/upvote.png" height="24px" width="24px" alt="upvote"></div>
                                {:else}
                                    <div><img src="/images/downvote.png" height="24px" width="24px" alt="downvote"></div>
                                {/if}
                                <div class="feedbackText">{response.text}</div>
                            </div>
                        {/each}
                    {:else}
                        <div class="emptySuggestion">
                            No related suggestion recieved yet.
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    </div>
{:else}
    <div class="writeFeedbackCont">
        <div class="header">
            <Feedback /> MEETING FEEDBACK
        </div>

        {#if userFeedbackPage === "vote"}
            <div class="question">{question}</div>
            <div class="votePageCont">
                <div class="voteBtns">
                    <button class="upvote" class:selected={status !== undefined && status === true} on:click={() => vote(true)}><img src="/images/upvote.png" height="24px" width="24px" alt="upvote"></button>
                    <button class="downvote" class:selected={status !== undefined && status === false} on:click={() => vote(false)}><img src="/images/downvote.png" height="24px" width="24px" alt="downvote"></button>
                </div>
                <div class="note">Answers will be anonymous</div>
            </div>
        {:else if userFeedbackPage === "typeFeedback"}
            <div class="question">{question}</div>
            <div class="typeFeedbackPageCont">
                <div class="feedbackQuestion">What's the #1 thing that can be improved?</div>
                <textarea class="questionInput" name="feedbackText" id="feedbackText" bind:value={feedbackText} maxlength="250" placeholder="Enter your feedback here" />
                <div class="enterFeebackBtnCont">
                    <button class="backBtn" on:click={() => changeFeedbackPage("vote")}>Back</button>
                    <button class="submitBtn" on:click={submitOptionalfeedbackText}>Submit Feedback</button>
                </div>
                <div class="note">Answers will be anonymous</div>
            </div>
        {:else if userFeedbackPage === "completed"}
            <div class="fettiCont">
                <div class="fetti">
                    <Fetti />
                    <div class="fettiIcon"><img src="/images/fettiIcon.png" width="60px" height="60px" alt="holdup" /></div>
                </div>
                <div class="completionText">Thank you <br /> for the feedback!</div>
            </div>
        {/if}
    </div>
{/if}


<style>
    .writeFeedbackCont {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    }

    .header {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: #8989A7;
        gap: 10px;
    }

    .votePageCont, .typeFeedbackPageCont, .votesPageOptions, .answerPageCont {
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
        padding: 20px;
    }

    .answerPageCont {
        padding-bottom: 0px;
    }

    .question {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #060D19;
        opacity: 0.84;
        max-height: 71px;
        overflow-y: auto;
        word-wrap: break-word;
    }

    .allVotedQuestion {
        font-size: 14px;
        line-height: 19px;
        max-height: 57px;
        overflow: auto;
    }

    .questionInput {
        background: #FFFFFF;
        outline: none;
        border: 1px solid #DCDCDC;
        border-radius: 10px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        min-height: 79px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        width: 100%;
    }

    .feedbackQuestion {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #636F84;
    }

    .note {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #636F84;
        justify-content: center;
    }

    .voteBtns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .upvote, .downvote {
        background: #FFFFFF;;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        width: 140px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 10px;
    }

    .selected {
        background: #F5F5F5;
    }

    .enterFeebackBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 10px;
    }

    .backBtn, .submitBtn {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        width: 140px;
        height: 40px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #565E6C;
        cursor: pointer;
    }

    .submitBtn {
        background: #6C44A8;
        color: #FFFFFF;
    }

    .fettiCont {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 10px;
    }

    .completionText {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #060D19;
        opacity: 0.84;
    }

    .fetti {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .fettiIcon {
        position: absolute;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);
    }

    .count {
        width: 24px;
        height: 24px;
        background: #F0F0F0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #060D19;
        opacity: 0.84;
    }

    .feedbackCont {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 170px;
        overflow: auto;
    }

    .feedback {
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        padding: 16px 35.5px 15px 35.5px;
        gap: 10px;
    }

    .feedbackText {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: left;
        color: #636F84;
        opacity: 0.84;
        max-height: 37px;
        overflow: auto;
        width: 20rem;
    }

    .exportCont {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6C44A8;
        width: 89px;
        cursor: pointer;
    }

    .creatorOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
    }

    .newQuestion {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #6C44A8;
        cursor: pointer;
    }

    .suggestions {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #636F84;
        cursor: pointer;
    }

    .answersOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .back {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #636F84;
        cursor: pointer;
    }

    .everyoneVoted {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #060D19;
        opacity: 0.84;
    }

    .emptySuggestion {
        font-family: 'Manrope';
        font-style: normal;
        font-size: 14px;
        font-weight: 700;
        color: #636F84;
        text-align: center;
        margin-top: 10px;
    }

    .highestVote {
        background: #FFF9E8;;
        border: 1px solid #F2CB5E;
    }

</style>