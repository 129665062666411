<script lang="ts">
    import { showNewQuestionModal } from "../services/store";
    import Cancel from "../icons/cancel.svelte";
    import { dbIncreaseRoundValue } from "../services/database";

    const moveToNextRound = async () => {
        try {
            await dbIncreaseRoundValue();
        } catch (err) {
            console.log(err);
        } finally {
            showNewQuestionModal.set(false);
        }
    }
</script>

<div class="modalCont">
    <div class="modal">
        <div class="closeModal" on:click={() => showNewQuestionModal.set(false)}><Cancel /></div>
        <div class="questionText">New Question</div>
        <div class="instruction">Are you sure you would like to  start a new question? doing so will lose current question data.</div>
        <div class="btnCont">
            <button class="confirm" on:click={moveToNextRound}>Yes</button>
            <button class="cancel" on:click={() => showNewQuestionModal.set(false)}>Cancel</button>
        </div>
    </div>
</div>


<style>
    .modalCont {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }
    
    .modalCont:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        content: '';
        z-index: 10;
    }

    .modal {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        z-index: 11;
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 25px;
        margin: 10px;
    }

    .questionText {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: #060D19;
        opacity: 0.84;
    }

    .instruction {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: rgba(6, 13, 25, 0.5);
    }

    .btnCont {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 10px;
        margin-top: calc(53px - 25px);
    }

    .confirm, .cancel {
        background: #6C44A8;
        border-radius: 8px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        width: 101px;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    }

    .cancel {
        background: #FFFFFF;
        color: #85858B;
        border: 1px solid #B5B5C8;
    }

    .closeModal {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
</style>