export const params = new URLSearchParams(window.location.search);
export const getParams = function (key) {
    return params.get(key);
};
export const getGameSessionId = function () {
    return `${getParams("roomId")}+${getParams("sessionId")}`;
};
export const convertToCSV = (arr) => {
    const array = [Object.keys(arr[0])].concat(arr);
    return array.map(it => Object.values(it).toString().replace(/(\r\n|\n|\r)/gm, "")).join('\n');
};
export const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
export function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-');
}
