import { derived, writable } from "svelte/store";
import { getParams } from "./utils";
import { user } from "./database";
export const myUserStore = writable({
    userName: user.id,
    profilePicture: user.profilePicture,
    id: user.id
});
export const usersStore = writable([]);
export const onlineUserStore = writable([]);
export const currentPage = writable("");
export const isCreator = writable(false);
export const gameCreatorId = writable("");
export const showNewQuestionModal = writable(false);
export const lastRoundIncompleted = writable(false);
export const isGameCreator = derived(gameCreatorId, $gameCreatorId => {
    if ($gameCreatorId === "")
        return getParams("isCreator") === "true";
    return $gameCreatorId === user.id;
});
