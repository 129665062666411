<script lang="ts">
	import { myUserStore, usersStore, gameCreatorId, onlineUserStore, showNewQuestionModal, lastRoundIncompleted } from "../services/store";
	import type { User } from "../interfaces/users.interface";
	import type firebase from "firebase/app";
	
	import {
		dbUsers,
		listenOnFirebaseKey,
		user,
		dbCreator,
		dbGameSessionRoundValue,
		dbGameSessionRounds,
	} from "../services/database";

	import CreateFeedback from "../pages/CreateFeedback.svelte";
	import WriteFeedback from "../pages/WriteFeedback.svelte";
	import NewQuestionModal from "../components/NewQuestionModal.svelte";
	import { getParams } from "../services/utils";

	let page: string;
	let dbPageKey: firebase.database.Reference;
	let roundValue: string;

	$ : {
		if (roundValue !== undefined && +roundValue > 1) {
			(async () => {
				const res = await dbGameSessionRounds.child(String(+roundValue - 1)).child("feebacks").child(user.id || getParams("userId")).child("status").get();
				
				if (!res.val()) lastRoundIncompleted.set(true);
				else lastRoundIncompleted.set(false);
			})();
		}
	}

	listenOnFirebaseKey(dbCreator(), val => gameCreatorId.set(val), 
		async () => getParams("isCreator") === "true" ? await dbCreator().set(user.id) : null);


	function onPageChange(snap: firebase.database.DataSnapshot) {
		if (!snap.exists()) {
			page = "CREATE_FEEDBACK_SCREEN";
			return;
		}
		page = snap.val();
	}

	dbGameSessionRoundValue.on("value", async snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
		if (dbPageKey) dbPageKey.off("value", onPageChange);
		dbPageKey = dbGameSessionRounds.child(roundValue).child("page");
		if (getParams("isCreator") === "true") await dbCreator().set(user.id);
		dbPageKey.on("value", onPageChange);
	});

	dbUsers.on("value", snap => {
		if (!!snap.val()) {
			usersStore.set(snap.val());
			const users: User[] = Object.values(snap.val());
			myUserStore.set(users.find(u => u.id === user.id));
			onlineUserStore.set(users.filter(u => u.online === true));
		}
	});
</script>

<div class="container">
	{#if page === "CREATE_FEEDBACK_SCREEN"}
	 	<CreateFeedback {roundValue} />
	{:else if page === "WRITE_FEEDBACK_SCREEN"}
		<WriteFeedback />
	{/if}
</div>

{#if $showNewQuestionModal}
	<NewQuestionModal />
{/if}
<style>
	:global(*) {
		box-sizing: border-box;
	}
	.container {
		width: 100%;
		height: 100%;
		font-family: Manrope;
		position: absolute;
		z-index: 10;
		overflow: hidden;
		background: #FFFFFF;
		border-radius: 10px;
	}
</style>